<template>
  <form @submit.prevent="save">
    <loading v-if="!isNew && !promotion" />

    <div v-else>
      <b-field label="Name">
        <b-input
          v-model="form.name"
          placeholder="Enter promotion name"
          :disabled="processing"
        />
      </b-field>

      <b-field label="Description">
        <textarea-autosize
          v-model="form.description"
          :min-height="30"
          :max-height="200"
          :disabled="processing"
          placeholder="Please type promotion description here"
          rows="1"
          class="textarea has-margin-bottom-50"
          autosize
        />
      </b-field>

      <b-field label="Calculation logic">
        <selector
          :value="
            calculationLogicItems.find(i => i.id === form.calculationLogic)
          "
          :items="calculationLogicItems"
          :required="true"
          @input="onChangeCalculationLogic"
        />
      </b-field>

      <b-field label="Provision">
        <b-select
          v-model="form.provision"
          expanded
          :disabled="processing || !isNew"
        >
          <option
            v-for="option in provisions"
            :key="option.value"
            :value="option.value"
            >{{ option.label }}</option
          >
        </b-select>
      </b-field>

      <div class="columns has-margin-bottom-0 is-multiline">
        <div
          v-for="(currency, key) in currencies"
          :key="key"
          class="column is-12"
        >
          <b-field>
            <p class="control is-expanded">
              <b-input
                v-model.number="form.provisioningData[key]"
                type="number"
                expanded
                step="0.01"
                min="0"
                :disabled="processing"
                :max="isPercent ? 100 : null"
              />
            </p>
            <p class="control">
              <button type="button" tabindex="-1" class="button">
                {{ currency.code }}
              </button>
            </p>
          </b-field>
        </div>
      </div>

      <b-field label="Use limit">
        <b-input
          v-model.number="form.useLimit"
          type="number"
          placeholder="Enter use limit"
          :disabled="processing"
          min="1"
        />
      </b-field>

      <b-field label="Use limit per user">
        <b-input
          v-model.number="form.useLimitPerUser"
          type="number"
          placeholder="Enter use limit"
          :disabled="processing"
          min="1"
        />
      </b-field>

      <b-field v-if="!isNew" label="Use count">
        <b-input
          v-model.number="form.useCount"
          type="number"
          placeholder="Enter use count"
          disabled
          min="0"
        />
      </b-field>
    </div>
  </form>
</template>

<script>
import _ from "lodash";
import currencies from "@src/data/currencies.json";
import {
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc
} from "@firebase/firestore";
export default {
  name: "PromotionDetails",
  props: {
    promotionId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      internalPromotionId:
        this.promotionId || doc(collection(this.$firestore, `promotions`)).id,
      loading: false,
      processing: false,
      fields: [
        "name",
        "description",
        "provision",
        "provisioningData",
        "dateCreated",
        "useCount",
        "useLimit",
        "useLimitPerUser",
        "calculationLogic"
      ],
      currencies,
      provisions: [
        { value: "creditDeposit", label: "Credit Deposit" },
        { value: "percent", label: "Percent" },
        { value: "fixedAmount", label: "Fixed Amount" }
      ],
      form: {
        name: "",
        description: "",
        provision: "creditDeposit",
        provisioningData: _.mapValues(currencies, () => 0),
        useCount: 0,
        useLimit: 1,
        useLimitPerUser: 1,
        calculationLogic: ""
      }
    };
  },
  computed: {
    promotion() {
      return this.$store.getters["promotion/promotion"](this.promotionId);
    },
    isValid() {
      if (this.$_.isEmpty(this.internalPromotionId)) return false;
      if (this.$_.isEmpty(this.form.name)) return false;
      if (this.$_.isEmpty(this.form.description)) return false;
      this.$_.each(this.currencies, (currency, key) => {
        if (!this.$_.isNumber(this.form.provisioningData[key])) return false;
      });
      if (!this.$_.isNumber(this.form.useCount)) return false;
      if (!this.$_.isNumber(this.form.useLimit)) return false;
      if (!this.$_.isNumber(this.form.useLimitPerUser)) return false;
      if (this.$_.get(this.form, "useLimit", 0) === 0) return false;
      return true;
    },
    isNew() {
      return this.$_.isEmpty(this.promotionId);
    },
    isPercent() {
      return this.form.provision === "percent";
    },
    hasChange() {
      return !this.$_.isEqual(
        this.$_.pick(this.form, this.fields),
        this.$_.pick(this.promotion, this.fields)
      );
    },
    calculationLogicItems() {
      return [
        {
          id: "",
          leftLabel:
            "Compound: the promotion will be applied after other discounts (eg. tier discounts)"
        },
        {
          id: "non-cumulative",
          leftLabel:
            "Best price: only the highest discount will be applied (eg. tier discount OR promotion)"
        }
      ];
    }
  },
  watch: {
    processing(newVal) {
      this.$emit("processing", newVal);
    },
    isValid(newVal) {
      this.$emit("valid", newVal);
    },
    loading(newVal) {
      this.$emit("loading", newVal);
    },
    hasChange: {
      handler(newVal) {
        this.$emit("hasChange", newVal);
      },
      immediate: true
    },
    promotion: {
      handler(newVal) {
        if (newVal) {
          this.$_.merge(
            this.form,
            this.$_.pick(this.$_.cloneDeep(newVal), this.fields)
          );
        }
      },
      immediate: true
    }
  },
  created() {
    this.$store.dispatch("promotion/observePromotion", {
      promotionId: this.promotionId || "NO_ID"
    });
  },
  beforeDestroy() {
    this.$store.dispatch("promotion/unobservePromotion", {
      promotionId: this.promotionId || "NO_ID"
    });
  },
  methods: {
    async save() {
      if (!this.isValid) return;

      this.processing = true;

      let form = this.isNew ? this.form : this.$_.omit(this.form, ["useCount"]);

      if (this.isNew) {
        this.$set(this.form, "dateCreated", serverTimestamp());
      }

      let promotionRef = doc(
        this.$firestore,
        `promotions`,
        this.internalPromotionId
      );

      (this.isNew ? setDoc(promotionRef, form) : updateDoc(promotionRef, form))
        .then(() => {
          this.processing = false;
          this.$emit("success", promotionRef.id);
          this.$toast.open({
            message: `Promotion saved`,
            position: "is-bottom",
            queue: false,
            type: "is-success"
          });
        })
        .catch(() => {
          this.processing = false;
          this.$toast.open({
            message: `Error saving promotion`,
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        });
    },
    onChangeCalculationLogic(value) {
      if (this.$_.isNil(value)) return;
      this.form.calculationLogic = value.id;
    }
  }
};
</script>
